import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layouts';

const IndexPage: React.SFC<{
  data: any;
}> = ({ data }) => (
  <Layout title="Slew Systems LLC">
    <Container className="mb-5">
      <Row>
        <Col>
          <Img fixed={data.splash.childImageSharp.fixed} />
        </Col>
      </Row>
    </Container>
  </Layout>
);

export const query = graphql`
  query {
    splash: file(relativePath: { eq: "splash.png" }) {
      childImageSharp {
        fixed(width: 1000) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

export default IndexPage;
